import Sortable from 'sortablejs'

function reorder(formset) {
  formset
    .querySelectorAll('.order-field, .order-field-container > input')
    .forEach((input, index) => {
      input.value = index
    })
}

export function initFormset(formset) {
  const formsetList = formset.querySelector('.formset-list')
  if (!formsetList) {
    return
  }
  const sortableList = formset.querySelector('.sortable')
  const addEmpty = formset.querySelector('.formset-add-empty')
  const prefix = formset.dataset.prefix
  const totalFormsInput = formset.querySelector(`#id_${prefix}-TOTAL_FORMS`)

  //init sortable if sortableList exists
  if (sortableList) {
    /* eslint-disable no-new */
    new Sortable(sortableList, {
      handle: '.handle',
      animation: 150,
      ghostClass: 'bg-blue-100',
      onEnd: (evt) => {
        reorder(formset)
      },
    })
  }

  // copy empty form and add it to formset
  if (addEmpty) {
    addEmpty.addEventListener('click', (evt) => {
      evt.preventDefault()
      const formIndex = parseInt(totalFormsInput.value)
      const emptyForm = formset.querySelector('.empty-form')
      const newForm = emptyForm.cloneNode(true)
      newForm.innerHTML = newForm.innerHTML.replace(/__prefix__/g, formIndex)
      totalFormsInput.value = formIndex + 1
      newForm.classList.remove('empty-form')
      formsetList.appendChild(newForm)
      reorder(formset)
      const event = new Event('elementsAdded')
      event.detail = { target: newForm }
      window.dispatchEvent(event)
    })
  }

  // delete action is element with .delete-field
  formsetList.addEventListener('click', (evt) => {
    if (!evt.target.classList.contains('delete-field')) {
      return
    }
    evt.preventDefault()
    evt.target.closest('.formset-form').remove()
    totalFormsInput.value = parseInt(totalFormsInput.value) - 1
  })
}
