import $ from 'jquery'
import { DEFAULT_OPTIONS, processResults } from './select2helpers'

export function siAddress($el, cityEl) {
  const selectElement = document.createElement('select')
  selectElement.classList.add('form-control')
  selectElement.name = $el.attr('name')
  const val = $el.val()
  if (val) {
    const optionElement = document.createElement('option')
    optionElement.value = val
    optionElement.textContent = val
    selectElement.appendChild(optionElement)
  }
  $el.after(selectElement)
  $el.data('selectElement', selectElement)

  const opts = {
    ...DEFAULT_OPTIONS,
    minimumInputLength: 1,
    templateResult: function (obj) {
      if (obj.loading) return obj.text
      return obj.a
    },
    ajax: {
      url: '/api/v1/search-rpe/',
      dataType: 'json',
      processResults,
    },
  }

  $(selectElement).select2(opts)

  $(selectElement).on('select2:select', function (event) {
    cityEl.val(event.params.data.c)
  })
}

export default function initializeSiAddress(el) {
  const $el = $(el)
  const $countryField = $(`#${el.dataset.country}`)
  const $city = $(`#${el.dataset.siaddress}`)
  siAddress($el, $city)
  const selectElement = $el.data('selectElement')
  const update = function () {
    const isDomestic = $countryField.val() === '167'
    $el.attr('disabled', isDomestic)
    $(selectElement).attr('disabled', !isDomestic)
    $el.toggle(!isDomestic)
    $(selectElement).data('select2').$container.toggle(isDomestic)
  }
  $countryField.on('change', update)
  update()
}
