export default () => ({
  items: [],
  visibleNum: undefined,
  additionalItems: [],
  moreOpen: false,
  moreButtonWidth: 0,
  resizeObserver: undefined,
  compute() {
    const availableWidth = this.$refs.nav.clientWidth - this.moreButtonWidth
    let width = 0
    this.additionalItems = []
    this.visibleNum = undefined
    this.$refs.nav.querySelectorAll('a').forEach((el, index) => {
      width += el.clientWidth
      if (width > availableWidth) {
        if (this.visibleNum === undefined) {
          this.visibleNum = index
        }
        this.additionalItems.push(this.items[index])
      }
    })
  },
  init() {
    this.items = Array.from(this.$refs.nav.querySelectorAll('a'), (item) => ({
      url: item.href,
      title: item.innerText,
    }))
    //console.log(this.items);
    this.$nextTick(() => {
      this.moreButtonWidth = this.$refs.moreButton.clientWidth
      this.resizeObserver = new ResizeObserver((entries) => {
        this.compute()
      })
      this.resizeObserver.observe(this.$refs.nav)
    })
  },
  isVisible(index) {
    return this.visibleNum === undefined || index < this.visibleNum
  },
  destroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
})
