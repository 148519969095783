import $ from 'jquery'

/**
 * Clicking on el convert date to iso format and copy to input field.
 *
 * @param {} el - element with data-insert-day data attribute
 */
export function initializeInsertDay(el) {
  const $el = $(el)
  $el.on('click', function (event) {
    const val = $el.text()
    $('#' + $el.attr('data-insert-day')).val(val)
    event.preventDefault()
    return false
  })
}
