/**
 * Handle selecting rows in tables.
 *
 * Listen to checkbox with data-select-all and select/deselect all table rows.
 */
export function tableSelectAll() {
  document.addEventListener('change', (evt) => {
    const el = evt.target
    if (el.tagName !== 'INPUT') {
      return
    }
    const checked = el.checked
    if (el.dataset.selectAll !== undefined) {
      const table = evt.target.closest('table')
      table
        .querySelectorAll('tbody tr')
        .forEach((tr) => tr.classList.toggle('selected', checked))
      table.querySelectorAll('input[name=selection]').forEach((input) => {
        input.checked = checked
      })
    } else if (el.name === 'selection') {
      evt.target.closest('tr').classList.toggle('selected', checked)
    }
  })
}

const HIDDEN_CLASS = 'hidden'

function getTableConfig(tableEl) {
  const config = {}
  tableEl.querySelectorAll('thead th').forEach((el) => {
    const text = el.textContent.trim()
    if (text === '') {
      return
    }
    config[el.dataset.name] = {
      label: text,
      show: !el.classList.contains(HIDDEN_CLASS),
    }
  })
  return config
}

document.body.addEventListener('htmx:configRequest', function (evt) {
  if (!evt.target.dataset.setupTable) {
    return
  }
  const tableEl = document.querySelector(
    `table[data-name=${evt.target.dataset.setupTable}]`
  )
  if (!tableEl) {
    return
  }
  evt.detail.parameters.name = tableEl.dataset.name
  evt.detail.parameters['current-config'] = getTableConfig(tableEl)
  evt.detail.parameters['default-config'] = tableEl.dataset.defaultConfig
})

document.body.addEventListener('app:toggle-table-column', function (evt) {
  const tableEl = document.querySelector(`table[data-name=${evt.detail.name}]`)
  const thEl = tableEl.querySelector(`thead th[data-name=${evt.detail.key}]`)
  const index = Array.from(thEl.parentNode.children).indexOf(thEl)
  if (index === -1) {
    return
  }
  tableEl
    .querySelectorAll(`td:nth-child(${index + 1}), th:nth-child(${index + 1})`)
    .forEach((cell) => {
      cell.classList.toggle(HIDDEN_CLASS, !evt.detail.visible)
    })
})

export function handleTableBulkActions(event) {
  if (event.target.dataset.selectTable === undefined) {
    return
  }
  if (event.target.tagName.toLowerCase() !== 'form') {
    return
  }
  const form = event.target

  // Remove any existing hidden selection inputs in the form
  const existingHiddenInputs = form.querySelectorAll(
    'input[type="hidden"][name="selection"]'
  )
  existingHiddenInputs.forEach((input) => {
    input.remove()
  })

  // Get the closest parent .table-and-pagination element
  const tableAndPagination = form.closest('.table-and-pagination')

  // If a tableAndPagination element is found, process the checkboxes
  if (tableAndPagination) {
    const checkboxes = tableAndPagination.querySelectorAll(
      'input[type="checkbox"][name="selection"]'
    )

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        const hiddenInput = document.createElement('input')
        hiddenInput.type = 'hidden'
        hiddenInput.name = 'selection'
        hiddenInput.value = checkbox.value
        form.appendChild(hiddenInput)
      }
    })
  }
}
