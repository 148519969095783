export function makePattern(term) {
  const keywords = term.split(/\s/)
  return new RegExp(`(${keywords.join('|')})`, 'ig')
}

export function textIncludesAllTerms(text, term) {
  for (const keyword of term.toLowerCase().split(/\s/)) {
    if (!text.toLowerCase().includes(keyword)) {
      return false
    }
  }
  return true
}

/**
 * highlightText.
 *
 * @param {} text
 * @param {} term
 */
export function highlightText(text, term) {
  if (!term) {
    return text
  }
  const pattern = makePattern(term)
  const result = text.replace(pattern, (match) => `<em>${match}</em>`)
  return result
}


export function searchText(text, term) {
  if (!term) {
    return true
  }
  return text.match(makePattern(term))
}

