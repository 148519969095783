import $ from 'jquery'

$(document).ready(function () {
  'use strict'

  let modalCounter = 0

  function xhrPost(url, replaceEl) {
    $.post(url)
      .done(function (data) {
        const html = $(data)
        replaceEl.replaceWith(html)
        html.filter('[data-refresh-url]').ajaxDataRefresh()
      })
      .fail(function (jqhr) {
        const text = jqhr.responseText || 'Napaka'
        const html = `<div class="alert alert-danger" role="alert">${text}</div>`
        replaceEl.replaceWith(html)
      })
  }

  $(document).on('click', function (event) {
    if ($(event.target).is('[data-xhr-post]')) {
      const targetEl = $(event.target)
      event.preventDefault()
      xhrPost(targetEl.attr('data-xhr-post'), $(targetEl.attr('data-replace')))
    }

    function createModal(id, title, body) {
      const modal = $(`
  <div id="${id}" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">${title}</h4>
        </div>
        <div class="modal-body">
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
  `)
      $('.modal-body', modal).append(body)
      $('body').append(modal)
    }

    if ($(event.target).is('[data-xhr-post-modal]')) {
      const targetEl = $(event.target)
      event.preventDefault()
      const bodyEl = $('<div/>')
      const modalId = 'modal' + modalCounter

      const options = {
        modalBackdrop: 'static',
        ...targetEl.data(),
      }
      modalCounter += 1
      createModal(modalId, options.modalTitle, bodyEl)
      const modalEl = $('#' + modalId)
      modalEl.modal({ backdrop: options.modalBackdrop })
      xhrPost(targetEl.attr('data-xhr-post-modal'), bodyEl)
      modalEl.on('hidden.bs.modal', function (e) {
        $('[data-refresh-url]', modalEl).ajaxDataRefresh('stop')
      })
    }
  })
})
