import $ from 'jquery'

export function initSearchPersonFormHelper(form) {
  const $form = $(form)
  const $existing = $('.existing', $form)
  const $nonexisting = $('.nonexisting', $form)
  const $save = $('.btn-save', $form)
  const $document = $('#div_id_document', $form)
  const $warning = $('.warning', $form)
  const $personFields = $('.person-field', $form)
  const $search = $('.search', $form)
  const $back = $('.back', $form)
  const $existingField = $('.existing-field')
  let isValid

  const reset = function () {
    $existing.hide()
    $nonexisting.hide()
    $save.hide()
    $document.hide()
    $warning.hide()
    $back.hide()
    $search.show()
    $personFields.prop('readonly', false)
    $('#form-errors', $form).remove()
  }
  reset()

  $back.click(reset)
  $form.submit(function (evt) {
    // remove disableDoublePost
    $form.data('submitted', false)
    if (!$save.is(':visible')) {
      evt.preventDefault()
      return false
    }
  })

  $search.click(function () {
    isValid = $personFields.map(function () {
        return $(this).val() !== ''
      }).get().every(Boolean)
    if (!isValid) {
      $warning.show()
      return
    }
    $warning.hide()
    const serialized = $form.serialize()
    $personFields.prop('readonly', true)
    $search.hide()
    $.get($search.data('url'), serialized, function (data) {
      $back.show()
      if (data.pk) {
        $existingField.val(data.pk)
        $('.person', $existing).text(data.full_name_with_birthday)
        $existing.show()
      } else {
        $existingField.val('')
        $existing.hide()
        $nonexisting.show()
        $save.show()
        $document.show()
      }
      $save.show()
    }).fail(function (data) {
      if (data.status === 400) {
        $form.append(
          '<div id="form-errors" class="error text-danger">' +
            data.responseJSON.errors +
            '</div>'
        )
      } else {
        $warning.show()
      }
      $back.show()
    })
  })
}
