import Alpine from 'alpinejs'
import { adjustPopoverPosition } from '../htmlUtils'

const storage = new Map()

async function getPopoverElement(url) {
  if (!storage.has(url)) {
    const response = await fetch(url)
    if (response.status !== 200) {
      return null
    }
    storage.set(url, await response.text())
  }
  const popoverEl = document.createElement('div')
  popoverEl.classList.add('popover-info-content')
  popoverEl.innerHTML = storage.get(url)

  return popoverEl
}

export default function popoverInfoDirective(
  el,
  { expression, value },
  { cleanup }
) {
  let open = false
  let timerIn
  let timerOut
  let popoverEl
  const url = expression

  Alpine.bind(el, {
    '@mouseenter'() {
      if (timerOut) {
        clearTimeout(timerOut)
        timerOut = undefined
      }
      if (!open && !timerIn) {
        timerIn = window.setTimeout(async () => {
          if (!popoverEl) {
            popoverEl = await getPopoverElement(url)
            if (popoverEl) {
              el.append(popoverEl)
              adjustPopoverPosition(popoverEl)
            }
          }
          if (popoverEl) {
            popoverEl.classList.add('open')
            open = true
          }
        }, 500)
      }
    },
    '@mouseover.outside'() {
      if (timerIn) {
        clearTimeout(timerIn)
        timerIn = undefined
      }
      if (open && !timerOut) {
        timerOut = window.setTimeout(() => {
          open = false
          popoverEl.classList.remove('open')
        }, 500)
      }
    },
  })
}
