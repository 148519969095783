import $ from 'jquery'
import { setSelect2Value } from './select2helpers'

/**
 * open popup
 *
 * @param {} url
 * @param {} el
 */
export function popup(url, el) {
  const windowName = 'popup-' + el
  const newwindow = window.open(url, windowName, 'height=600,width=900')
  if (window.focus) {
    newwindow.focus()
  }
  return false
}

/**
 * handle selecting in popup to update opener window field
 *
 * @param {} windowName
 * @param {} val
 * @param {} text
 */
export function onChoosen(windowName, val, text) {
  const el = windowName.replace(/^popup-/, '')
  const $target = $('#' + el)
  const $current = $('#current-' + el)
  if ($target.is('select')) {
    setSelect2Value($target, { id: val, text: text })
  } else {
    $target.val(val)
    $current.text(text)
  }
}

/**
 * onAddToSelect.
 *
 * @param {} windowName
 * @param {} val
 * @param {} text
 */
export function onAddToSelect(windowName, val, text) {
  const el = windowName.replace(/^popup-/, '')
  const $target = $('#' + el)
  $target.append($('<option></option>').val(val).html(text))
  $target.val(val)
}

/**
 * special handling of links with [data-pk] attribute
 */
export function initLinkClickHandlerInPopup() {
  if ($('body.popup').length !== 0) {
    // remove all hx-get attributes that would otherwide trigger a hx request for detail page
    $('.table tr').removeAttr('hx-get');
    $(document).on('click', 'a[data-pk]', function (event) {
      window.opener.onChoosen(
        window.name,
        $(this).data('pk'),
        $(this).data('text')
      )
      window.close()
      event.preventDefault()
      return false
    })
  }
}

/**
 * initPopupLinks - open links with [data-popup] in popup
 */
export function initPopupLinks() {
  $(document).on('click', 'a[data-popup]', function (event) {
    popup(this.href)
    event.preventDefault()
    return false
  })
}
