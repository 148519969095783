import $ from 'jquery'
import 'select2'
import translations from 'select2/src/js/select2/i18n/sl'
import { highlightText } from './textUtils'

export const DEFAULT_OPTIONS = {
  width: 'style',
  language: translations,
}

/**
 * Wraps DRF results in results, add pagination.
 */
export const processResults = (data, params) => {
  if (data.results) {
    const results = {
      pagination: {
        more: !!data.next,
      },
      results: data.results,
    }
    return results
  } else {
    return { results: data }
  }
}

// select2 for input=text with ajax
/**
 * initializeSelect2WithAjax.
 *
 * @param {} el
 */
export function initializeSelect2WithAjax(el) {
  const $el = $(el)
  const multiple = Boolean($el.attr('data-multiple'))

  //skip fields which names contains __prefix__
  const fieldName = $el.attr('name')
  if (fieldName && fieldName.indexOf('__prefix__') !== -1) {
    return
  }

  const passFields = ($el.attr('data-pass-fields') || '')
    .split(',')
    .filter((e) => e !== '')
  const $form = $el.closest('form')
  let currentTerm = ''

  $el.select2({
    ...DEFAULT_OPTIONS,
    minimumInputLength: 0,
    allowClear: true,
    placeholder: $el.attr('data-placeholder'),
    containerCssClass: 'form-control',
    multiple: multiple,
    templateResult: (state) =>
      state.loading
        ? state.text
        : $(`<span>${highlightText(state.text, currentTerm)}</span>`),
    ajax: {
      url: $el.attr('data-url'),
      dataType: 'json',
      data: (params) => {
        currentTerm = params.term
        const query = {
          ...params,
        }
        passFields.forEach(
          (key) => (query[key] = $(`[name=${key}]`, $form).val())
        )
        return query
      },
      processResults,
    },
  })
}

//TODO: remove
window.initializeSelect2WithAjax = initializeSelect2WithAjax

/**
 * initializeSelect2ForSelect.
 *
 * @param {} el
 */
export function initializeSelect2ForSelect(el) {
  const placeholder = el.attr('data-placeholder') || 'izberite...'
  // TODO: allowClear should be set to 'true' only if there is empty value
  el.select2({
    ...DEFAULT_OPTIONS,
    allowClear: true,
    placeholder: placeholder,
  })
}

//select2-with-image
/**
 * formatSelect2WithImage.
 *
 * @param {} state
 */
function formatSelect2WithImage(state) {
  const imgUrl = $(state.element).data('image')
  const imgHtml = imgUrl
    ? `<img class="select2-option-img__image" src="${imgUrl}"> `
    : ''
  return $(`<span class="select2-option-img">${imgHtml}${state.text}</span>`)
}

/**
 * initializeSelect2ForSelectWithImage.
 *
 * @param {} el
 */
export function initializeSelect2ForSelectWithImage(el) {
  const placeholder = el.attr('data-placeholder') || 'izberite...'
  $(el).select2({
    ...DEFAULT_OPTIONS,
    allowClear: true,
    templateResult: formatSelect2WithImage,
    templateSelection: formatSelect2WithImage,
    selectionCssClass: 'select2-with-image__selection',
    placeholder: placeholder,
  })
}

/**
 * initializeSelect2ForSelect.
 *
 * @param {} el
 */
export function initializeSelect2Picker(el) {
  const placeholder = el.attr('data-placeholder') || 'izberite...'
  el.select2({
    ...DEFAULT_OPTIONS,
    dropdownCssClass: 'select2-picker__dropdown',
    selectionCssClass: 'select2-picker__selection',
    closeOnSelect: false,
    placeholder: placeholder,
  })
}

export const setSelect2Value = (el, data, clean = false) => {
  if (!data) {
    if (clean) {
      el.val(null).trigger('change')
    }
    return
  }
  // create the option and append to Select2
  const option = new Option(data.text, data.id, true, true)
  el.append(option).trigger('change')

  // manually trigger the `select2:select` event
  el.trigger({
    type: 'select2:select',
    params: {
      data: data,
    },
  })
}

/**
 * update select2 value from other element
 */
export function initSelect2Updater() {
  window.addEventListener('click', (ev) => {
    if (!ev.target.dataset.select2Update) {
      return
    }
    setSelect2Value(
      $(document.getElementById(ev.target.dataset.select2Update)),
      {
        text: ev.target.dataset.select2UpdateText,
        id: ev.target.dataset.select2UpdateId,
      },
      true
    )
  })
}
