export function loadScript(url) {
  return new Promise(function (resolve, reject) {
    const script = document.createElement('script')
    script.onload = resolve
    script.onerror = reject
    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}

