import { createIntl, createIntlCache } from '@formatjs/intl'

import messages from './locale/sl.json'

const cache = createIntlCache()

const intl = createIntl(
  {
    locale: 'sl-SI',
    messages,
  },
  cache
)

export default intl
