/* global htmx */

import $ from 'jquery'
import Tab from 'bootstrap/js/dist/tab'
import 'select2'
import 'htmx.org/dist/ext/alpine-morph.js'
import Alpine from 'alpinejs'
import morph from '@alpinejs/morph'

import './modals'
import * as textUtils from './textUtils'
import nav from './alpine/nav'
import menu from './alpine/menu'
import greedyNav from './alpine/greedyNav'

import initializeTooltip from './tooltips'
import { tableSelectAll, handleTableBulkActions } from './tableHelpers'
import {
  popup,
  onChoosen,
  onAddToSelect,
  initLinkClickHandlerInPopup,
  initPopupLinks,
} from './popupHelpers.js'
import './initHtmx'
import initWidgets from './initWidgets'
import { reloadFixtures } from './fixtureOfficials'
import * as legacy from './legacy'
import popoverInfoDirective from './alpine/popoverInfoDirective'
import { handleImageChange, assertConfirmed } from './formHelpers'
import { initSelect2Updater } from './select2helpers'
import './setupJQuery'

document.addEventListener('submit', handleTableBulkActions)

$(function () {
  initWidgets(document.body)

  const tabEl = document.querySelector(
    `[data-bs-target="${document.location.hash}"]`
  )
  if (tabEl) {
    new Tab(tabEl).show()
  }

  // this is used in modalHelper.js and formset.js
  //handle adding new elements
  window.addEventListener('elementsAdded', (ev) => {
    initWidgets(ev.detail.target)
  })

  //NOTE: workaround for https://github.com/select2/select2/issues/5993
  $(document).on('select2:open', () => {
    document
      .querySelector('.select2-container--open .select2-search__field')
      .focus()
  })
  // NOTE: workaround for Select2 not emitting native events
  // https://github.com/select2/select2/issues/1908
  $(document).on('select2:select', (evt) => {
    evt.target.dispatchEvent(new Event('change'))
  })

  // initialize handling links in popup
  initLinkClickHandlerInPopup()

  //initialize tableSelectAll
  tableSelectAll()

  initPopupLinks()

  // update image fields thumbnails
  document.addEventListener('change', (event) => {
    if (event.target.dataset.imageField === undefined) {
      return
    }
    handleImageChange(event.target, () => {
      // image-field-changed is triggered only when event was not result of editing
      if (!event.detail?.edited) {
        const ev = new Event('image-field-changed')
        event.target.dispatchEvent(ev)
      }
    })

  })

  document.addEventListener('reloadFixtures', reloadFixtures)

  initSelect2Updater()
})


// TODO: remove when legacy CompetitionCalendarEditApp react app is removed
const i = document.createElement('input');
i.setAttribute('type', 'date');
window.DATE_NATIVE_INPUT = !(i.type === 'text');

window.jQuery3 = $
window.$ = $
window.app = {
  textUtils: textUtils,
  initializeTooltip,
  assertConfirmed,
  legacy: legacy,
}
// popup helpers
window.popup = popup
window.onChoosen = onChoosen
window.onAddToSelect = onAddToSelect

Alpine.plugin(morph)
Alpine.data('nav', nav)
Alpine.data('menu', menu)
Alpine.data('greedyNav', greedyNav)
Alpine.directive('popover-info', popoverInfoDirective)

window.Alpine = Alpine
Alpine.start()

window.htmx = htmx
