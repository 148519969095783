/**
 * @param {String} HTML representing a single element
 * @return {Element}
 */
export function htmlToElement(html) {
  const template = document.createElement('template')
  html = html.trim()
  template.innerHTML = html
  return template.content.firstChild
}

export function findClosestRelativeElement(el) {
  while (el && el !== document.body) {
    const style = getComputedStyle(el)
    if (style.position === 'relative') {
      return el
    }
    el = el.parentElement
  }
  return null
}

export function adjustPopoverPosition(popoverEl) {
  const relativeElement = findClosestRelativeElement(popoverEl.parentElement)
  // Check if closest relative element has overflow after adding new element
  if (
    relativeElement &&
    relativeElement.scrollHeight > relativeElement.clientHeight
  ) {
    // If overflow, adjust the top position of the new element
    let newTop = parseInt(window.getComputedStyle(popoverEl).top)
    newTop -= relativeElement.scrollHeight - relativeElement.clientHeight
    popoverEl.style.top = `${newTop}px`
  }
}

