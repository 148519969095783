import * as TD from '@eonasdan/tempus-dominus'

const SL = {
  today: 'Danes',
  clear: 'Počisti',
  close: 'Zapri',
  selectMonth: 'Izberite mesec',
  previousMonth: 'Prejšnji mesec',
  nextMonth: 'Naslednji mesec',
  selectYear: 'Izberite leto',
  previousYear: 'Prejšnje Leto',
  nextYear: 'Naslednje leto',
  selectDecade: 'Izberite desetletje',
  previousDecade: 'Prejšnje desetletje',
  nextDecade: 'Naslednje desetletje',
  previousCentury: 'Prejšnje stoletje',
  nextCentury: 'Naslednje stoletje',
  pickHour: 'Izberite uro',
  incrementHour: 'Povečaj ure',
  decrementHour: 'Zmanjšaj uro',
  pickMinute: 'Izberite minuto',
  incrementMinute: 'Povečaj minuto',
  decrementMinute: 'Zmanjšaj minuto',
  pickSecond: 'Izberite drugo',
  incrementSecond: 'Povečaj sekundo',
  decrementSecond: 'Zmanjšaj sekundo',
  toggleMeridiem: 'Preklop dopoldne/popoldne',
  selectTime: 'Izberite čas',
  selectDate: 'Izberite Datum',
  dayViewHeaderFormat: { month: 'long', year: 'numeric' },
  locale: 'sl',
  startOfTheWeek: 1,
}

const DEFAULT_OPTIONS = {
  localization: SL,
  //https://github.com/Eonasdan/tempus-dominus/issues/2575
  //allowInputToggle: true,
  useCurrent: false,
}

export function initializeDatePicker(el) {
  const td = new TD.TempusDominus(el, {
    ...DEFAULT_OPTIONS,
    localization: {
      format: 'dd.MM.yyyy',
      locale: 'sl-SI',
    },
    display: {
      viewMode: 'calendar',
      components: {
        decades: false,
        year: true,
        month: true,
        date: true,
        clock: false,
      },
    },
  })
  return td
}

export function initializeDateTimePicker(el) {
  const td = new TD.TempusDominus(el, {
    ...DEFAULT_OPTIONS,
    localization: {
      format: 'dd.MM.yyyy HH:mm',
      locale: 'sl-SI',
    },
    display: {
      viewMode: 'calendar',
      components: {
        decades: false,
        year: true,
        month: true,
        date: true,
        clock: true,
      },
    },
  })
  return td
}
