import $ from 'jquery'

import moment from 'moment'
import 'daterangepicker'

const DATE_FORMAT = 'DD.MM.YYYY'

const DEFAULT_RANGES = {
  Danes: [moment(), moment()],
  Včeraj: [moment().add({ days: -1 }), moment().add({ days: -1 })],
  'Zadnjih 7 dni': [moment().add({ days: -6 }), moment()],
  'Zadnjih 30 dni': [moment().add({ days: -29 }), moment()],
  'Ta mesec': [moment().startOf('month'), moment().endOf('month')],
  'Prejšnji mesec': [
    moment().startOf('month').add({ months: -1 }),
    moment().startOf('month').add({ days: -1 }),
  ],
  'Naslednjih 30 dni': [moment(), moment().add({ days: 30 })],
  'To leto': [moment().startOf('year'), moment().endOf('year')],
  'Prejšnje leto': [
    moment().startOf('year').add({ years: -1 }),
    moment().endOf('year').add({ years: -1 }),
  ],
}

function setInputValue(ev, picker) {
  $(this).val(
    picker.startDate.format(DATE_FORMAT) +
      ' - ' +
      picker.endDate.format(DATE_FORMAT)
  )
  $(this).trigger('change')
}

function clearInputValue() {
  $(this).val('')
  $(this).trigger('change')
}

export function initializeDaterangepicker(el) {
  $(el).daterangepicker({
    autoUpdateInput: false,
    opens: 'left',
    ranges: DEFAULT_RANGES,
    locale: {
      format: DATE_FORMAT,
      applyLabel: 'Potrdi',
      cancelLabel: 'Prekliči',
      fromLabel: 'Od',
      toLabel: 'Do',
      customRangeLabel: 'Po meri',
      daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
      monthNames: [
        'Januar',
        'Februar',
        'Marec',
        'April',
        'Maj',
        'Junij',
        'Julij',
        'Avgust',
        'September',
        'Oktober',
        'November',
        'December',
      ],
      firstDay: 1,
    },
  })
  $('input.daterange').on('apply.daterangepicker', setInputValue)
  $('input.daterange').on('cancel.daterangepicker', clearInputValue)
}
