/* global htmx */
import initWidgets from './initWidgets'
import intl from './initIntl'
import { htmlToElement } from './htmlUtils'

function displayToast(title, content) {
  const closeLabel = intl.formatMessage({
    id: 'modal.close',
    defaultMessage: 'Close',
  })
  const html = `
      <div
        x-data="{ show: false }"
        x-init="$nextTick(() => show = true)"
        x-show="show"
        x-transition:enter="transform ease-out duration-300 transition"
        x-transition:enter-start="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        x-transition:enter-end="translate-y-0 opacity-100 sm:translate-x-0"
        x-transition:leave="transition ease-in duration-100"
        x-transition:leave-start="opacity-100"
        x-transition:leave-end="opacity-0"
        class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div
          class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <svg class="h-8 w-8 text-red-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900">${title}</p>
              <p class="mt-1 text-sm text-gray-500">${content || ''}</p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button @click="show = false" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">{closeLabel}</span>
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      `
  document.getElementById('notifications').appendChild(htmlToElement(html))
}

htmx.on('htmx:afterSettle', function (evt) {
  initWidgets(evt.detail.target)
  const htmxRequestInfo = evt.detail.target.querySelector(
    '[data-htmx-request-info]'
  )
  if (htmxRequestInfo) {
    if (htmxRequestInfo.dataset.updateNavigation) {
      const ev = new Event('update-navigation')
      ev.detail = htmxRequestInfo.dataset.updateNavigation
      window.dispatchEvent(ev)
    }
    if (htmxRequestInfo.dataset.title) {
      document.title = htmxRequestInfo.dataset.title
    }
  }
})

const STATUS_CODES = {
  404: 'error.404',
  403: 'error.403',
  500: 'error.500',
  413: 'error.413',
}

htmx.on('htmx:responseError', function (evt) {
  const status = evt.detail.xhr?.status
  let content
  if (STATUS_CODES[status]) {
    content = intl.formatMessage({
      id: STATUS_CODES[status],
      defaultMessage: '',
    })
  } else {
    content = null
  }
  displayToast(
    intl.formatMessage(
      {
        id: 'error',
        defaultMessage: 'Error',
      },
      { status }
    ),
    content
  )
})
htmx.on('htmx:sendError', function (evt) {
  displayToast(
    intl.formatMessage({
      id: 'connectionError',
      defaultMessage: 'Connection error',
      description: 'Connection error',
    })
  )
})
