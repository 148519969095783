import $ from 'jquery'

/**
 * disableDoublePost.
 */
export function disableDoublePost() {
  const $form = $(this)
  $(this).on('submit', function (e) {
    if (e.isDefaultPrevented && e.isDefaultPrevented()) {
      return
    }
    if ($form.data('submitted') === true) {
      console.log('disableDoublePost')
      e.preventDefault()
    } else {
      $form.data('submitted', true)
    }
  })
}

/**
 * Hide initial form fieldset, open it on clicking button inside form.
 * Used to hide comment in confirm forms for decline action.
 *
 * @param {} form
 */
export function hideInitial(form) {
  const $form = $(form)
  const $fieldset = $('fieldset', $form)
  $fieldset.hide()
  $('button', $form).on('click', function (ev) {
    if ($form.hasClass('form-opened')) {
      return true
    }

    $form.addClass('form-opened')
    $fieldset.show()
    ev.preventDefault()
    return false
  })
}

/*
 * Listen to change in field and trigger toggleFunc with $el, mapping
 * specified in data-type attribute and allFields
 * arguments
 */
export const toggleFields = function (type, toggleFunc, parentEl) {
  const setupElement = function ($el) {
    const mapping = JSON.parse($el.attr('data-' + type))
    //var allFields = _.uniq(_.flatten(_.values(mapping)));
    const allFields = [...new Set(Object.values(mapping).flat(2))]

    const bindToggleFunc = toggleFunc.bind(this, $el, mapping, allFields)
    $el.change(bindToggleFunc)
    $el.on('parent-toggle', e => {
      bindToggleFunc()
    })
    bindToggleFunc()
  }

  $('[data-' + type + ']').each(function () {
    setupElement($(this))
  })
}

export const getFieldValue = function ($el) {
  let value
  if ($el.is(':disabled')) {
    return null
  }
  if ($el[0].type === 'radio') {
    value = $('input[name=' + $el.attr('name') + ']:checked').val()
  } else if ($el[0].type === 'checkbox') {
    value = $el.is(':checked') && 'on'
  } else {
    value = $el.val()
  }
  return value
}

export const toggleRequired = function ($el, mapping, allFields) {
  allFields.forEach((id) => {
    $('label[for=' + id + ']').removeClass('requiredField')
  })
  const value = getFieldValue($el)
  if (value && mapping[value]) {
    mapping[value].forEach((id) => {
      document.querySelector(`label[for=${id}]`).classList.add('requiredField')
    })
  }
}

/**
 * Enable fields in id container if value is truthy, eventually hides container.
 *
 * @param {string} id - container id
 * @param {boolean} value - enable fields if truthy
 * @param {boolean} hide - hide container if truthy
 * @returns {Array} - list of enabled fields
 */
export function enableField(id, value, hide = false) {
  const $control = $('#' + id).parents('.form-group')
  const $inputs = $('input, select, textarea', $control)
  const $formControl = $('.form-control', $control)

  if (value) {
    if ($control.hasClass('disabled-field')) {
      $control.removeClass('disabled-field')
      $inputs.prop('disabled', false)
      $formControl.removeClass('form-control-disabled')
    }
    if (hide) {
      $control.show()
    }
    return($inputs.toArray())
  } else {
    if (!$control.hasClass('disabled-field')) {
      $control.addClass('disabled-field')
      $inputs.prop('disabled', true)
      $formControl.addClass('form-control-disabled')
    }
    if (hide) {
      $control.hide()
    }
    return []
  }
}

/*
 * `__ALL__` - is catch all mapping
 */
export const toggleEnabled = function ($el, mapping, allFields) {
  let visible
  const value = getFieldValue($el)

  if (Object.prototype.hasOwnProperty.call(mapping, value)) {
    visible = mapping[value]
  } else if (Object.prototype.hasOwnProperty.call(mapping, '__ALL__')) {
    visible = mapping.__ALL__
  } else {
    visible = []
  }

  const enabledElements = []
  allFields.forEach((id) => {
    enabledElements.push(...enableField(id, visible.includes(id)))
  })
  $(enabledElements).trigger('parent-toggle')
}

/*
 * `__ALL__` - is catch all mapping
 */
export const toggleShowEnabled = function ($el, mapping, allFields) {
  let visible
  const value = getFieldValue($el)

  if (Object.prototype.hasOwnProperty.call(mapping, value)) {
    visible = mapping[value]
  } else if (Object.prototype.hasOwnProperty.call(mapping, '__ALL__')) {
    visible = mapping.__ALL__
  } else {
    visible = []
  }

  const enabledElements = []
  allFields.forEach((id) => {
    enabledElements.push(...enableField(id, visible.includes(id), true))
  })
  $(enabledElements).trigger('parent-toggle')
}

/*
 * `__ALL__` - is catch all mapping
 */
export const toggleDisabled = function ($el, mapping, allFields) {
  let visible
  const value = getFieldValue($el)

  if (Object.prototype.hasOwnProperty.call(mapping, value)) {
    visible = mapping[value]
  } else if (Object.prototype.hasOwnProperty.call(mapping, '__ALL__')) {
    visible = mapping.__ALL__
  } else {
    visible = []
  }

  const enabledElements = []
  allFields.forEach((id) => {
    enabledElements.push(...enableField(id, !visible.includes(id)))
  })
  $(enabledElements).trigger('parent-toggle')
}

export const disableField = function ($el, enabled) {
  const $control = $el.parents('.form-group')
  const $inputs = $('input, select, textarea', $control)
  if (enabled) {
    $control.removeClass('disabled-field')
    $inputs.prop('disabled', false)
  } else {
    $control.addClass('disabled-field')
    $inputs.prop('disabled', true)
  }
}

export function initFillFields(el) {
  $(el).on('click', function (event) {
    const val = $(el).text()
    $('#' + $(el).attr('data-fill-field')).val(val)
    event.preventDefault()
    return false
  })
}

export function assertConfirmed(e, confirmLabel) {
  if (!window.confirm(confirmLabel)) {
    e.preventDefault()
    return false
  }
  return true
}

// update thumbnail when image field is changed
export function handleImageChange(field, cb = null) {
  const container = field.closest('[data-image]')
  const thumbnail = container.querySelector('[data-image-thumbnail]')
  if (field.value) {
    const reader = new FileReader()
    reader.onload = function (e) {
      thumbnail.src = e.target.result
      if (cb) {
        cb()
      }
    }
    delete thumbnail.dataset.originalUrl
    reader.readAsDataURL(field.files[0])
    thumbnail.classList.remove('hidden')
  } else {
    thumbnail.classList.add('hidden')
    thumbnail.removeAttribute('src')
  }
}
