function getIdValues(obj) {
  const ids = []

  function traverse(node) {
    ids.push(node.id)
    if (Array.isArray(node.children)) {
      node.children.forEach(traverse)
    }
  }

  obj.forEach(traverse)

  return ids.sort((a, b) => b.length - a.length)
}

function findUrlMatch(url, idValues) {
  const parts = url.split('/').filter((part) => part !== '')

  while (parts.length > 0) {
    const currentUrl = '/' + parts.join('/') + '/'
    if (idValues.includes(currentUrl)) {
      return currentUrl
    }
    parts.pop()
  }

  return null
}

export default (elementId, path) => ({
  items: JSON.parse(document.getElementById(elementId).textContent),
  active: null,
  term: '',
  termPattern: null,
  opened: {},
  setActive(id) {
    this.active = id
  },
  init() {
    this.idValues = getIdValues(this.items)
    this.setActive(findUrlMatch(path, this.idValues))
    window.addEventListener('update-navigation', (evt) => {
      if (evt.detail) {
        this.setActive(findUrlMatch(evt.detail, this.idValues))
        this.opened = {}
      }
    })
  },
  initialOpened(item) {
    return (
      item.id === this.active || item.children.some((e) => e.id === this.active)
    )
  },
  isOpened(item) {
    if (this.term && !item.item.hidden) {
      return true
    }
    if (this.opened[item.id] === undefined) {
      return this.initialOpened(item)
    } else {
      return this.opened[item.id]
    }
  },
  toggle(item) {
    if (this.opened[item.id] === undefined) {
      this.opened[item.id] = !this.initialOpened(item)
    } else {
      this.opened[item.id] = !this.opened[item.id]
    }
  },
  highlight(title) {
    if (!this.termPattern) {
      return title
    }
    return title.replace(
      this.termPattern,
      (match) => `<strong>${match}</strong>`
    )
  },
  search() {
    if (!this.term) {
      this.termPattern = null
      for (const item of this.items) {
        item.item.hidden = false
        for (const subitem of item.children) {
          subitem.item.hidden = false
        }
      }
    }
    this.termPattern = window.app.textUtils.makePattern(this.term)
    for (const item of this.items) {
      let parentMatch = false
      let childrenMatch = false
      parentMatch = window.app.textUtils.textIncludesAllTerms(
        item.item.title,
        this.term
      )
      item.item.hidden = !parentMatch
      for (const subitem of item.children) {
        if (parentMatch) {
          subitem.item.hidden = false
        } else {
          const submitemMatch = window.app.textUtils.textIncludesAllTerms(
            subitem.item.title,
            this.term
          )
          subitem.item.hidden = !submitemMatch
          childrenMatch = childrenMatch || submitemMatch
        }
      }
      if (childrenMatch) {
        item.item.hidden = false
      }
    }
  },
})
