/* global htmx */
import $ from 'jquery'
import initWidgets from './initWidgets'

export function reloadFixtures() {
  const jqhr = $.getJSON(window.RELOAD_URL, { fixture_ids: window.FIXTURE_IDS })
  jqhr.done(function (data) {
    Object.entries(data).forEach(([key, html]) => {
      const $el = $('#' + key)
      if ($('form', $el).length > 0) {
        return
      }
      const $newEl = $(html)
      $el.replaceWith($newEl)
      htmx.process($newEl.get(0))
      initWidgets($newEl.get(0))
    })
  })
}
