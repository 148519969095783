import $ from 'jquery'
import Tab from 'bootstrap/js/dist/tab'

import { initSearchPersonFormHelper } from './searchPersonFormHelper'
import {
  disableDoublePost,
  hideInitial,
  initFillFields,
  toggleRequired,
  toggleEnabled,
  toggleDisabled,
  toggleFields,
  toggleShowEnabled,
} from './formHelpers'
import { initFormset } from './formsetHelpers'
import { initializeInsertDay } from './linkHelpers'
import {
  initializeDatePicker,
  initializeDateTimePicker,
} from './datePickerHelpers'
import { initializeDaterangepicker } from './daterangepickerHelpers'
import {
  initializeSelect2WithAjax,
  initializeSelect2ForSelect,
  initializeSelect2ForSelectWithImage,
  initializeSelect2Picker,
} from './select2helpers'
import initializeTooltip from './tooltips'
import initializeSiAddress from './siAddress'

export default function initWidgets(target) {
  //INITIALIZE SELECT2
  $('select.select2[data-url]', target).each(function (index, el) {
    initializeSelect2WithAjax(el)
  })

  // select2 for select
  $('select.select2:not([data-url])', target).each(function () {
    const $el = $(this)
    if ($el.hasClass('select2-with-image')) {
      initializeSelect2ForSelectWithImage($(this))
    } else {
      initializeSelect2ForSelect($el)
    }
  })

  // select2 for selectpicker
  $('.selectpicker', target).each(function (index, el) {
    initializeSelect2Picker($(el))
  })

  //INITIALIZE daterangepicker
  $('input.daterange', target).each(function () {
    initializeDaterangepicker($(this))
  })

  //INITIALIZE tooltips
  $('[data-im-toggle=popover], [data-im-toggle=tooltip]', target).each(
    function (index, el) {
      initializeTooltip(el)
    }
  )

  //INITIALIZE date pickers
  $('.datepicker', target).each(function (index, el) {
    initializeDatePicker(el)
  })
  $('.datetimepicker', target).each(function (index, el) {
    initializeDateTimePicker(el)
  })

  //INITIALIZE tabs
  $('[data-bs-toggle=tab]', target).each(function (index, el) {
    const tabTrigger = new Tab(el)
    el.addEventListener('click', function (event) {
      event.preventDefault()
      tabTrigger.show()
      window.location.hash = event.target.dataset.bsTarget
    })
  })

  //INITIALIZE formsetHelpers
  $('.formset', target).each(function (index, el) {
    initFormset(el)
  })

  //INITIALIZE data-insert-day
  $('[data-insert-day]', target).each(function (index, el) {
    initializeInsertDay(el)
  })

  // INITIALIZE disableDoublePost
  $(
    'form.registration-form, form.default-form, form.action-form, .delegate-report-form',
    target
  ).not("[data-skip-disable-double-post]").each(disableDoublePost)

  //INITIALIZE searchPersonFormHelper
  $('form.search-person-form', target).each(function (index, el) {
    initSearchPersonFormHelper(el)
  })

  $('form.form-hidden-initial').each(function (i, form) {
    hideInitial(form)
  })

  // INITALIZE form helpers
  toggleFields('required', toggleRequired, target)
  toggleFields('enabled', toggleEnabled, target)
  toggleFields('disabled', toggleDisabled, target)
  toggleFields('show-enabled', toggleShowEnabled, target)
  target.querySelectorAll('[data-fill-field]').forEach(initFillFields)

  // siAddress
  $('[data-siaddress]', target).each(function (index, el) {
    initializeSiAddress(el)
  })

  // INITALIZE dyanmic scripts
  // club module
  async function loadClubModule() {
    const { default: setupClubsModule } = await import(
      /* webpackChunkName: "clubs" */ './clubs'
    )
    setupClubsModule(target)
  }
  if (target.querySelector('[data-module-clubs]')) {
    loadClubModule()
  }

  async function loadClublicencingModule() {
    const { default: setupClublicencingModule } = await import(
      /* webpackChunkName: "clublicencing" */ './clublicencing'
    )
    setupClublicencingModule(target)
  }
  if (target.querySelector('[data-module-clublicencing]')) {
    loadClublicencingModule()
  }

  async function loadCompetitionModule() {
    const { default: setupCompetitionModule } = await import(
      /* webpackChunkName: "competition" */ './competition'
    )
    setupCompetitionModule(target)
  }
  if (target.querySelector('[data-module-competition]')) {
    loadCompetitionModule()
  }

  // image cropper
  async function loadImageCropper(el) {
    const { default: setupImageCropper } = await import(
      /* webpackChunkName: "imageCropper" */ './imageCropper'
    )
    setupImageCropper(el)
  }
  $('[data-image-crop]', target).each(function (index, el) {
    loadImageCropper(el)
  })

  async function loadGallery(el) {
    const { default: setupGallery } = await import(
      /* webpackChunkName: "gallery" */ './gallery'
    )
    setupGallery (el)
  }
  $('.gallery', target).each(function (index, el) {
    loadGallery(el)
  })
}
